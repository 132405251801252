import React from "react";
import { classDecider } from "../utils/helpers";
import { ButtonBaseComponent } from "./ButtonBase";
import { ButtonComponentProps } from "./types/ButtonComponentProps";
import styles from "./styles/button.module.scss";

const ButtonComponent = React.forwardRef<
  HTMLButtonElement,
  ButtonComponentProps
>(
  (
    {
      id,
      className,
      href,
      onClick,
      children,
      startIcon,
      endIcon,
      variant,
      passive = false,
      type,
      ...props
    },
    ref
  ) => {
    let classes = classDecider({ variant, passive }, styles, "button");

    return (
      <button
        id={id}
        onClick={onClick}
        ref={ref}
        type={type ?? 'submit'}
        className={`${classes} ${className || ""}`}
        {...(props as React.HTMLAttributes<HTMLButtonElement>)}
      >
        {startIcon} <div>{children}</div> {endIcon}
      </button>
    );
  }
);

export const Button =
  ButtonBaseComponent<ButtonComponentProps>(ButtonComponent);
