import { Outlet } from "react-router"
import { PRODUCT_LOGO_LIGHT } from "../../config"
import styles from './styles/publicLayout.module.scss'
import { PublicLanguageSelect } from "./PublicLanguageSelect"

const PublicLayout = () => {
    return <div className={styles.public__container}>
        <PublicLanguageSelect />
        <Outlet />
        <div className={`${styles.bottom__logo}`}>
            <img src={PRODUCT_LOGO_LIGHT} />
        </div>
    </div>
}

export default PublicLayout