import { FormattedMessage, IntlShape } from 'react-intl';
import { enLanguage, shortEnLanguage, shortTrLanguage, trLanguage } from '../common/constants';
import { store } from '../stores';

export const translate = (id: string, values: any = undefined): any => {
    return <FormattedMessage id={id} values={values} />
}

export const translateString = (intl: IntlShape, id: string, values: any = undefined) => {
    return intl.formatMessage({ id }, values)
}

export const getShortLanguage = (lang: string) => {
    switch (lang) {
        case enLanguage:
            return shortEnLanguage;
        default:
            return shortTrLanguage;
    }
}

export const getLanguage = () => {
    switch (store.getState()?.settings.language) {
        case shortEnLanguage:
            return enLanguage;
        default:
            return trLanguage;
    }
}