import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Button } from "../../components/button/Button";
import { IconButton } from "../../components/button/IconButton";
import { NavApiKeys, NavDashboard, NavDocumentation, NavLiveInterface, NavModelTraining, NavSettings, NavSignout } from "../../components/icons";
import { Typography } from "../../components/typography/Typography";
import { SupportedLanguage } from "../../enums/supported-language";
import { StateModel } from "../../models/store/StateModel";
import { clearAuthToken } from "../../stores/auth/authSlice";
import { setLanguage } from "../../stores/settings/settingsSlice";
import { Url_Api_Keys, Url_Documentation, Url_Documentation_Guide, Url_Home, Url_Live_Interface, Url_Model_Training, Url_Settings, Url_Sign_In, Url_Spending_Details } from "../../utils/routeHelper";
import styles from "./styles/sideBar.module.scss";
import { SideBarProps } from "./types/SideBarProps";

export const SideBar = React.forwardRef<HTMLDivElement, SideBarProps>((props: SideBarProps, ref) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const language = useSelector((state: StateModel) => state?.settings?.language);

  const handleLogout = () => {
    dispatch(clearAuthToken(null));
    navigate(Url_Sign_In);
  };

  const handleNavigate = (path?: string) => {
    if (path) navigate(path);
  };

  return (
    <div ref={ref} className={`${styles.side__menu} ${props.isSideMenuOpen ? styles["side__menu--open"] : styles["side__menu--closed"]}`}>
      {[
        {
          icon: NavDashboard,
          title: "Home",
          path: [Url_Home, Url_Spending_Details],
          id: "home",
          // Component: Dashboard,
        },
        {
          icon: NavApiKeys,
          title: "ApiKeys",
          path: [Url_Api_Keys],
          id: "apiKeys",
          // Component: ApiKeys,
        },
        {
          icon: NavDocumentation,
          title: "Documentation",
          path: [Url_Documentation],
          id: "documentation",
          // Component: ApiKeys,
        },
        {
          icon: NavLiveInterface,
          title: "LiveInterface",
          path: [Url_Live_Interface],
          id: "liveInterface",
          // Component: ApiKeys,
        },
        {
          icon: NavModelTraining,
          title: "ModelTraining",
          path: [Url_Model_Training],
          id: "modelTraining",
          // Component: ApiKeys,
        },
        {
          icon: NavSettings,
          title: "Settings",
          id: "settings",
          path: [Url_Settings],
          // Component: Settings,
        },
      ].map((page) => {
        return (
          <IconButton
            key={`${page.id}-sidemenu-icon`}
            onClick={() => {
              handleNavigate(page.path[0]);
            }}
            href={page.path[0]}
            id={page.id}
            aria-selected={page.path.some(
              (x) => (x === Url_Home && location.pathname === Url_Home) || (x !== Url_Home && location.pathname.startsWith(x))
            )}
            size="large"
            color="white"
            className={`${styles.side__menu__button}`}
            icon={<page.icon />}
          />
        );
      })}
      <div className={styles.side__menu__flex}></div>
      <div className={styles.side__menu__language}>
        <Button
          onClick={() => {
            dispatch(setLanguage(SupportedLanguage.Eng));
          }}
          variant="text"
        >
          <Typography color={language === SupportedLanguage.Eng ? "primary" : "light"} size="base" weight="medium" label="EN" />
        </Button>
        <Button
          onClick={() => {
            dispatch(setLanguage(SupportedLanguage.Tur));
          }}
          variant="text"
        >
          <Typography color={language === SupportedLanguage.Tur ? "primary" : "light"} size="base" weight="medium" label="TR" />
        </Button>
      </div>
      <Button
        onClick={() => {
          handleLogout();
        }}
        size="medium"
        className={styles.side__menu__logout}
      >
        <NavSignout />
      </Button>
    </div>
  );
});
