import en from "./en"
import tr from "./tr"

const translations: { en: Record<string, string>, tr: Record<string, string> } = {
    en: {
        ...en
    },
    tr: {
        ...tr
    },
};

export default translations;