import { CustomerCreditRequest } from '../../../contracts/cloudai.api/request/common/CustomerCreditRequest';
import { getReq, getReqKey, postReq } from '../../base/http';

const apiPath = "credit/customercredit"

export const creditCustomerCreditGet = (id: string, signal: AbortSignal | undefined = undefined) => {
    return getReqKey(`${apiPath}/get`, id, undefined, { signal: signal }, 'customerId');
}

export const creditCustomerCreditGetCurrentCredit = (signal: AbortSignal | undefined = undefined) => {
    return getReq(`${apiPath}/getCurrentCredit`, undefined, { signal: signal });
}

export const creditCustomerCreditSave = (request: CustomerCreditRequest, signal: AbortSignal | undefined = undefined) => {
    return postReq(`${apiPath}/save`, request, undefined, { signal: signal });
}
