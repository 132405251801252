import React from "react";
import type { LoaderFunctionArgs } from "react-router-dom";
import { createBrowserRouter, redirect } from "react-router-dom";

import { ProtectedLayout } from "../pages/ProtectedLayout/ProtectedLayout";
import PublicLayout from "../pages/PublicLayout/PublicLayout";
import { store } from "../stores";

const ForgotPassword = React.lazy(() => import("../pages/ForgotPassword/ForgotPassword"));
const ResetPassword = React.lazy(() => import("../pages/ResetPassword/ResetPassword"));
const SignIn = React.lazy(() => import("../pages/SignIn/SignIn"));
const SignUp = React.lazy(() => import("../pages/SignUp/SignUp"));
const ConfirmationEmail = React.lazy(() => import("../pages/ConfirmationEmail/ConfirmationEmail"));

const Dashboard = React.lazy(() => import("../pages/Dashboard/Dashboard"));
const ApiServices = React.lazy(() => import("../pages/ApiServices/ApiServices"));
const ApiKey = React.lazy(() => import("../pages/ApiKeys/ApiKey"));
const Documentation = React.lazy(() => import("../pages/Documentation/Documentation"));
const DocumentationGuideDetailWhatIsArkApi = React.lazy(() => import("../pages/Documentation/pages/DocumentationGuideDetailWhatIsArkApi"));
const DocumentationGuideDetailWhatIsArkApiFor = React.lazy(() => import("../pages/Documentation/pages/DocumentationGuideDetailWhatIsArkApiFor"));
const DocumentationGuideDetailWhatDoesArkApiOffer = React.lazy(() => import("../pages/Documentation/pages/DocumentationGuideDetailWhatDoesArkApiOffer"));
const DocumentationGuideDetailHowDoesCreditWork = React.lazy(() => import("../pages/Documentation/pages/DocumentationGuideDetailHowDoesCreditWork"));
const DocumentationGuideDetailFunctionsOfArkApiDocument = React.lazy(() => import("../pages/Documentation/pages/DocumentationGuideDetailFunctionsDocument"));
const DocumentationGuideDetailFunctionsOfArkApiExtract = React.lazy(() => import("../pages/Documentation/pages/DocumentationGuideDetailFunctionsExtract"));
const DocumentationGuideDetailFunctionsOfArkApiOcr = React.lazy(() => import("../pages/Documentation/pages/DocumentationGuideDetailFunctionsOcr"));
const DocumentationGuideDetailFunctionsOfArkApiRotation = React.lazy(() => import("../pages/Documentation/pages/DocumentationGuideDetailFunctionsRotation"));
const DocumentationGuideDetailFunctionsOfArkApiSignature = React.lazy(() => import("../pages/Documentation/pages/DocumentationGuideDetailFunctionsSignature"));
const DocumentationGuideDetailFunctionsOfArkApiMetadata = React.lazy(() => import("../pages/Documentation/pages/DocumentationGuideDetailFunctionsMetadata"));

const Settings = React.lazy(() => import("../pages/Settings/Settings"));
const Payment = React.lazy(() => import("../pages/Payment/Payment"));
const SecureGateway = React.lazy(() => import("../pages/SecureGateway/SecureGateway"));
const PaymentStatus = React.lazy(() => import("../pages/PaymentStatus/PaymentStatus"));
const SpendingDetails = React.lazy(() => import("../pages/SpendingDetails/SpendingDetails"));
const ModelTraining = React.lazy(() => import("../pages/ModelTraining/ModelTraining"));
const LiveInterface = React.lazy(() => import("../pages/LiveInterface/LiveInterface"));

export const Path_Sign_In = "sign-in";
export const Path_Sign_Up = "sign-up";
export const Path_Sign_In_With_Api_Key = "sign-in-with-api-key";
export const Path_Forgot_Password = "forgot-password";
export const Path_Reset_Password = "reset-password";
export const Path_Confirmation_Email = "confirmation-email";
export const Path_Payment = "payment";
export const Path_Payment_Status = "payment/status";
export const Path_Payment_Status_Param = "payment/status/:status";
export const Path_Api_Services = "api-services";
export const Path_Api_Keys = "api-keys";
export const Path_Model_Training = "model-training";
export const Path_Documentation = "documentation";
export const Path_Documentation_Param = "documentation/:tab1";
export const Path_Documentation_Param_Param = "documentation/:tab1/:tab2";
export const Path_Documentation_Param_Param_Param = "documentation/:tab1/:tab2/:tab3";
export const Path_Documentation_Guide = "documentation/guide";
export const Path_Documentation_Guide_Detail = "documentation/guide/detail";
export const Path_Documentation_Guide_Detail_WhatIsArkApi = "documentation/guide/detail/what-is-arkapi";
export const Path_Documentation_Guide_Detail_WhatIsArkApiFor = "documentation/guide/detail/what-is-arkapi-for";
export const Path_Documentation_Guide_Detail_WhatDoesArkApiOffer = "documentation/guide/detail/what-does-arkapi-offer";
export const Path_Documentation_Guide_Detail_HowDoesCreditWork = "documentation/guide/detail/how-does-arkapi-credit-system-work";
export const Path_Documentation_Guide_Detail_FunctionsOfArkApiDocument = "documentation/guide/detail/functions-of-arkapi-document";
export const Path_Documentation_Guide_Detail_FunctionsOfArkApiExtract = "documentation/guide/detail/functions-of-arkapi-extract";
export const Path_Documentation_Guide_Detail_FunctionsOfArkApiOcr = "documentation/guide/detail/functions-of-arkapi-ocr";
export const Path_Documentation_Guide_Detail_FunctionsOfArkApiRotation = "documentation/guide/detail/functions-of-arkapi-rotation";
export const Path_Documentation_Guide_Detail_FunctionsOfArkApiSignature = "documentation/guide/detail/functions-of-arkapi-signature";
export const Path_Documentation_Guide_Detail_FunctionsOfArkApiMetadata = "documentation/guide/detail/functions-of-arkapi-metadata";

export const Path_Settings = "settings";
export const Path_Settings_Purchase = "settings/purchase";
export const Path_Settings_Param = "settings/:action";
export const Path_Spending_Details = "spending-details";
export const Path_Spending_Details_Param = "spending-details/:action";
export const Path_3d_Secure = "3d-secure";
export const Path_Live_Interface = "live-interface";
export const Path_Live_Interface_Param = "live-interface/:action/:service?";

export const Url_Home = `/`;
export const Url_Sign_In = `/${Path_Sign_In}`;
export const Url_Sign_Up = `/${Path_Sign_Up}`;
export const Url_Sign_In_With_Api_Key = `/${Path_Sign_In_With_Api_Key}`;
export const Url_Forgot_Password = `/${Path_Forgot_Password}`;
export const Url_Reset_Password = `/${Path_Reset_Password}`;
export const Url_Confirmation_Email = `/${Path_Confirmation_Email}`;
export const Url_Payment = `/${Path_Payment}`;
export const Url_Payment_Status = `/${Path_Payment_Status}`;
export const Url_Payment_Status_Param = `/${Path_Payment_Status_Param}`;
export const Url_Api_Services = `/${Path_Api_Services}`;
export const Url_Api_Keys = `/${Path_Api_Keys}`;
export const Url_Documentation = `/${Path_Documentation}`;
export const Url_Documentation_Param = `/${Path_Documentation_Param}`;
export const Url_Documentation_Param_Param = `/${Path_Documentation_Param_Param}`;
export const Url_Documentation_Param_Param_Param = `/${Path_Documentation_Param_Param_Param}`;
export const Url_Documentation_Guide = `/${Path_Documentation_Guide}`;
export const Url_Documentation_Guide_Detail = `/${Path_Documentation_Guide_Detail}`;
export const Url_Documentation_Guide_WhatIsArkApi = `/${Path_Documentation_Guide_Detail_WhatIsArkApi}`;
export const Url_Documentation_Guide_WhatIsArkApiFor = `/${Path_Documentation_Guide_Detail_WhatIsArkApiFor}`;
export const Url_Documentation_Guide_WhatDoesArkApiOffer = `/${Path_Documentation_Guide_Detail_WhatDoesArkApiOffer}`;
export const Url_Documentation_Guide_HowDoesCreditWork = `/${Path_Documentation_Guide_Detail_HowDoesCreditWork}`;
export const Url_Documentation_Guide_FunctionsOfArkApiDocument = `/${Path_Documentation_Guide_Detail_FunctionsOfArkApiDocument}`;
export const Url_Documentation_Guide_FunctionsOfArkApiExtract = `/${Path_Documentation_Guide_Detail_FunctionsOfArkApiExtract}`;
export const Url_Documentation_Guide_FunctionsOfArkApiOcr = `/${Path_Documentation_Guide_Detail_FunctionsOfArkApiOcr}`;
export const Url_Documentation_Guide_FunctionsOfArkApiRotation = `/${Path_Documentation_Guide_Detail_FunctionsOfArkApiRotation}`;
export const Url_Documentation_Guide_FunctionsOfArkApiSignature = `/${Path_Documentation_Guide_Detail_FunctionsOfArkApiSignature}`;
export const Url_Documentation_Guide_FunctionsOfArkApiMetadata = `/${Path_Documentation_Guide_Detail_FunctionsOfArkApiMetadata}`;
export const Url_Settings = `/${Path_Settings}`;
export const Url_Settings_Purchase = `/${Path_Settings_Purchase}`;
export const Url_Settings_Param = `/${Path_Settings_Param}`;
export const Url_Spending_Details = `/${Path_Spending_Details}`;
export const Url_Spending_Details_Param = `/${Path_Spending_Details_Param}`;
export const Url_3d_Secure = `/${Path_3d_Secure}`;
export const Url_Model_Training = `/${Path_Model_Training}`;
export const Url_Live_Interface = `/${Path_Live_Interface}`;
export const Url_Live_Interface_Param = `/${Path_Live_Interface_Param}`;

export let router = createBrowserRouter([
  {
    path: Url_Home,
    element: <></>,
  },
]);

export const routes = [
  {
    id: "root",
    path: Url_Home,
    loader: protectedLoader,
    Component: ProtectedLayout,
    children: [
      {
        path: Url_Home,
        Component: Dashboard,
      },
      {
        path: Url_Payment,
        Component: Payment,
      },
      {
        path: Url_Payment_Status,
        Component: PaymentStatus,
      },
      {
        path: Url_Payment_Status_Param,
        Component: PaymentStatus,
      },
      {
        path: Url_Api_Services,
        Component: ApiServices,
      },
      {
        path: Url_Api_Keys,
        Component: ApiKey,
      },
      {
        path: Url_Model_Training,
        Component: ModelTraining,
      },
      {
        path: Url_Settings,
        Component: Settings,
      },
      {
        path: Url_Settings_Param,
        Component: Settings,
      },
      {
        path: Url_Spending_Details,
        Component: SpendingDetails,
      },
      {
        path: Url_Spending_Details_Param,
        Component: SpendingDetails,
      },
      {
        path: Url_Live_Interface,
        Component: LiveInterface,
      },
      {
        path: Url_Live_Interface_Param,
        Component: LiveInterface,
      },
    ],
  },
  {
    id: "3dPayment",
    path: Path_3d_Secure,
    Component: SecureGateway,
  },
  {
    id: "public",
    path: Url_Home,
    loader: publicLoader,
    Component: PublicLayout,
    children: [
      {
        path: Url_Sign_In,
        Component: SignIn,
      },
      {
        path: Url_Sign_Up,
        Component: SignUp,
      },
      {
        path: Url_Forgot_Password,
        Component: ForgotPassword,
      },
      {
        path: Url_Reset_Password,
        Component: ResetPassword,
      },
      {
        path: Url_Confirmation_Email,
        Component: ConfirmationEmail,
      },
    ],
  },
  {
    id: "public-documentation",
    path: Url_Home,
    Component: ProtectedLayout,
    children: [
      {
        path: Url_Documentation,
        Component: Documentation,
      },
      {
        path: Url_Documentation_Param,
        Component: Documentation,
      },
      {
        path: Url_Documentation_Guide_WhatIsArkApi,
        Component: DocumentationGuideDetailWhatIsArkApi,
      },
      {
        path: Url_Documentation_Guide_WhatIsArkApiFor,
        Component: DocumentationGuideDetailWhatIsArkApiFor,
      },
      {
        path: Url_Documentation_Guide_WhatDoesArkApiOffer,
        Component: DocumentationGuideDetailWhatDoesArkApiOffer,
      },
      {
        path: Url_Documentation_Guide_HowDoesCreditWork,
        Component: DocumentationGuideDetailHowDoesCreditWork,
      },
      {
        path: Url_Documentation_Guide_FunctionsOfArkApiDocument,
        Component: DocumentationGuideDetailFunctionsOfArkApiDocument,
      },
      {
        path: Url_Documentation_Guide_FunctionsOfArkApiExtract,
        Component: DocumentationGuideDetailFunctionsOfArkApiExtract,
      },
      {
        path: Url_Documentation_Guide_FunctionsOfArkApiOcr,
        Component: DocumentationGuideDetailFunctionsOfArkApiOcr,
      },
      {
        path: Url_Documentation_Guide_FunctionsOfArkApiRotation,
        Component: DocumentationGuideDetailFunctionsOfArkApiRotation,
      },
      {
        path: Url_Documentation_Guide_FunctionsOfArkApiSignature,
        Component: DocumentationGuideDetailFunctionsOfArkApiSignature,
      },
      {
        path: Url_Documentation_Guide_FunctionsOfArkApiMetadata,
        Component: DocumentationGuideDetailFunctionsOfArkApiMetadata,
      },
      {
        path: Url_Documentation_Param_Param,
        Component: Documentation,
      },
      {
        path: Url_Documentation_Param_Param_Param,
        Component: Documentation,
      }
    ],
  },
];

export const createRoutes = () => {
  router = createBrowserRouter(routes);
};

async function redirectControl(request: Request) {
  let params = new URLSearchParams();
  const from = new URL(request.url).searchParams.get("from");
  if (from) {
    params.set("from", from);
  } else {
    const publicPages = [Url_Sign_In, Url_Sign_Up, Url_Sign_In_With_Api_Key, Url_Forgot_Password, Url_Reset_Password, Url_Confirmation_Email];
    const currentPage = new URL(request.url).pathname;
    if (!publicPages.includes(currentPage)) {
      params.set("from", new URL(request.url).pathname);
    }
  }

  return params;
}

async function publicLoader({ request }: LoaderFunctionArgs) {
  const params: URLSearchParams = await redirectControl(request);
  if (store?.getState().auth.authToken) {
    return redirect(params && params.get("from") !== null ? (params.get("from") as string) : `/`);
  }
  return null;
}

async function protectedLoader({ request }: LoaderFunctionArgs) {
  const params = await redirectControl(request);
  if (!store?.getState().auth.authToken) {
    return redirect(`${Path_Sign_In}?${params.toString()}`);
  }
  return null;
}
