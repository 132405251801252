import { ComponentType } from "react";
import { CheckCircleLight, InfoCircle } from "../../icons";

export interface ToastType {
    icon: ComponentType;
    progressBarClass: string;
}

export interface ToastTypes {
    success: ToastType;
    warning: ToastType;
    info: ToastType;
    error: ToastType;
}

export const toastTypes: ToastTypes = {
    success: {
        icon: CheckCircleLight,
        progressBarClass: "success",
    },
    warning: {
        icon: InfoCircle,
        progressBarClass: "warning",
    },
    info: {
        icon: InfoCircle,
        progressBarClass: "info",
    },
    error: {
        icon: InfoCircle,
        progressBarClass: "error",
    },
};
