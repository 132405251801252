import React from "react";
import { ContextProps } from "./ContextProps";

export type ToastType = 'success' | 'warning' | 'info' | 'error'

export interface ToastProps {
    id: string
    type: ToastType
    message: string
}

interface ToastMessageEvent<T> extends CustomEvent {
    detail: {
        type: ToastType;
        message: T;
    };
}

export const ToastContext = React.createContext<ContextProps | null>(null);

