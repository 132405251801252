import React, { ReactElement } from 'react';
import { LoadingProps } from './types/LoadingProps';
import styles from "./styles/loading.module.scss"
import { ReactComponent as Medium } from './assets/Medium.svg';
import { ReactComponent as Large } from './assets/Large.svg';
import { ReactComponent as XLarge } from './assets/XLarge.svg';
import { classDecider } from '../utils/helpers';

export const Loading = ({ size = 'medium', children }: LoadingProps) => {
    let classes = classDecider({ size }, styles, 'loading')

    const renderLoadingCircle = (key?: string) => {
        return <>
            <div
                key={key}
                className={`${classes}`}>
                {(size === 'medium' ? <Medium /> : (
                    size === 'large' ? <Large /> : <XLarge />
                ))}
            </div>
        </>
    }

    return (<>
        {children ?
            React.Children.map(children, (child, index) => {
                if (React.isValidElement(child)) {
                    return React.cloneElement((child as React.ReactElement<HTMLElement>), {
                        className: `${(child as React.ReactElement<HTMLElement>)?.props?.className} ${styles.loading__field}`,
                        key: `${index}-loading-element`,
                    }, [
                        (child as ReactElement)?.props.children,
                        renderLoadingCircle(`${index}-loading-circle`)
                    ])
                } else {
                    return <div key={`${index}-loading-element`}
                        className={`${styles.loading__field__screen}`}>
                        {renderLoadingCircle(`${index}-loading-circle`)}
                    </div>
                }
            }) : <div className={`${styles.loading__field__screen}`}>
                {renderLoadingCircle()}
            </div>
        }
    </>)
}