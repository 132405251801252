import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { IconButton } from "../../components/button/IconButton";
import { NavBack } from "../../components/icons";
import { Typography } from "../../components/typography/Typography";
import { PRODUCT_LOGO } from "../../config";
import { StateModel } from "../../models/store/StateModel";
import { translateString } from "../../utils/language";
import {
  Url_Documentation_Guide,
  Url_Documentation_Guide_Detail,
  Url_Live_Interface,
  Url_Payment,
  Url_Payment_Status,
  Url_Settings_Purchase
} from "../../utils/routeHelper";
import { ApiKeyCreditBlock } from "./ApiKeyCreditBlock";
import styles from "./styles/navBar.module.scss";
import { NavBarProps } from "./types/NavBarProps";

export const NavBar = (props: NavBarProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const intl = useIntl();
  const breadcrumbs = useSelector((state: StateModel) => state?.settings?.breadcrumbs);
  const isWithoutBrand =
    location.pathname.includes(Url_Payment_Status) ||
    location.pathname.includes(Url_Payment) ||
    location.pathname.includes(Url_Documentation_Guide_Detail);
  const isLiveInterface = location.pathname.includes(Url_Live_Interface);

  return (
    <div className={`${styles.navbar} ${isWithoutBrand && styles.navbar__back}`}>
      {isWithoutBrand && (
        <IconButton
          onClick={() => {
            if (location.pathname.includes(Url_Documentation_Guide_Detail)) {
              navigate(Url_Documentation_Guide);
            } else navigate(Url_Settings_Purchase);
          }}
          color="white"
          className={styles.navbar__back__button}
          icon={<NavBack />}
        />
      )}

      <div className={styles.navbar__container}>
        {breadcrumbs && (
          <div className={styles.navbar__breadcrumbs}>
            {breadcrumbs.map((item, index: number) => {
              return index === 0 ? (
                isWithoutBrand ? (
                  <Typography className="me-2" key={`breadcrumb-${item}`} size="2xl" color="light-blue" label={translateString(intl, item)} />
                ) : (
                  <Typography className="me-2" key={`breadcrumb-${item}`} size="2xl" color="dark-blue" label={translateString(intl, item)} />
                )
              ) : (
                <Typography
                  className={styles.navbar__breadcrumbs__list}
                  key={`breadcrumb-${item}`}
                  weight="light"
                  color="dark-blue"
                  label={translateString(intl, item)}
                />
              );
            })}
          </div>
        )}
        {!isWithoutBrand && !isLiveInterface && <img src={PRODUCT_LOGO} />}
        {!isWithoutBrand && isLiveInterface && <ApiKeyCreditBlock />}
      </div>
    </div>
  );
};
