import React from "react";
import { useLayoutEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import { RouterProvider } from "react-router";
import { shortEnLanguage } from "./common/constants";
import { Loading } from "./components/loading/Loading";
import { ToastProvider } from "./components/toast/ToastProvider";
import { StateModel } from "./models/store/StateModel";
import translations from "./translations";
import { createRoutes, router } from "./utils/routeHelper";
import "./scss/app.scss";

const App = () => {
  const [isPageReady, setIsPageReady] = useState(false);

  useLayoutEffect(() => {
    createRoutes();
    setIsPageReady(true);
  }, []);

  const language = useSelector(
    (state: StateModel) => state?.settings?.language
  );

  return (
    <ToastProvider>
      <React.Suspense>
        <IntlProvider
          locale={language ?? shortEnLanguage}
          messages={
            translations[language ?? shortEnLanguage] as Record<string, string>
          }
        >
          <div className="home">
            {isPageReady ? (
              <RouterProvider
                router={router}
                fallbackElement={
                  <>
                    <Loading />
                  </>
                }
              />
            ) : (
              <Loading size="xlarge" />
            )}
          </div>
        </IntlProvider>
      </React.Suspense>
    </ToastProvider>
  );
};

export default App;
