import React from "react";
import { Outlet, useLocation } from "react-router";
import { Url_Documentation_Guide_Detail, Url_Payment, Url_Payment_Status } from "../../utils/routeHelper";
import { NavBar } from "./NavBar";
import styles from "./styles/protectedLayout.module.scss";
import { SideBar } from "./SideBar";
import { store } from "../../stores";

export const ProtectedLayout = () => {
  const [isSideMenuOpen, setIsSideMenuOpen] = React.useState(false);
  const location = useLocation();

  const handleMenuClick = () => {
    setIsSideMenuOpen(!isSideMenuOpen);
  };

  return (
    <div
      className={`${styles.protected__container} 
    ${
      location.pathname.includes(Url_Payment_Status) ||
      location.pathname.includes(Url_Payment) || location.pathname.includes(Url_Documentation_Guide_Detail) || !store?.getState().auth.authToken
        ? styles.protected__container__without
        : ""
    }
        
    ${
      isSideMenuOpen
        ? styles["protected__container--open"]
        : styles["protected__container--closed"]
    }`}
    >
      <NavBar
        isSideMenuOpen={isSideMenuOpen}
        onMenuClick={handleMenuClick}
      ></NavBar>
      {!location.pathname.includes(Url_Payment_Status) &&
        !location.pathname.includes(Url_Payment) && !location.pathname.includes(Url_Documentation_Guide_Detail) && store?.getState().auth.authToken && (
          <SideBar isSideMenuOpen={isSideMenuOpen}></SideBar>
        )}
      <div id="protected-layout" className={`${styles.protected__container__page} ${location.pathname.includes("documentation/api-services") || location.pathname.includes("/documentation/guide") ? styles["protected__container__page--noPadding"] : " "}`}>
        <Outlet />
      </div>
    </div>
  );
};
