import { SupportedLanguage } from "../enums/supported-language";

// Language
export const trLanguage = 'tr-TR';
export const enLanguage = 'en-US';
export const itLanguage = 'it-IT';
export const shortTrLanguage = 'tr';
export const shortEnLanguage = 'en';
export const shortItLanguage = 'it';

export const SupportedLanguages = [SupportedLanguage.Eng, SupportedLanguage.Tur];

//Guid
export const emptyGuid = '00000000-0000-0000-0000-000000000000';
export const guidPattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
export const englishCharactersEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

const countryPhoneCodesList = [
    '+93', '+355', '+213', '+376', '+244', '+54', '+374', '+297', '+43', '+994', '+973', '+880', '+375', '+32', '+501', '+229', '+975', '+591', '+387', '+267', '+55', '+246', '+673', '+359',
    '+226', '+257', '+855', '+237', '+1', '+238', '+236', '+235', '+56', '+86', '+61', '+57', '+269', '+242', '+243', '+682', '+506', '+225', '+385', '+53', '+357', '+420', '+45', '+253', '+593', '+20', '+503',
    '+240', '+291', '+372', '+251', '+500', '+298', '+679', '+358', '+33', '+594', '+689', '+241', '+220', '+995', '+49', '+233', '+350', '+30', '+299', '+502', '+44-1481', '+224', '+245', '+592', '+509', '+504', '+852', '+36', '+354',
    '+91', '+62', '+98', '+964', '+353', '+44-1624', '+972', '+39', '+81', '+44-1534', '+962', '+254', '+686', '+850', '+82', '+965', '+996', '+856', '+371', '+961', '+266', '+231', '+218', '+423', '+370', '+352', '+853', '+389', '+261', '+265', '+60',
    '+960', '+223', '+356', '+692', '+596', '+222', '+230', '+262', '+52', '+691', '+373', '+377', '+976', '+382', '+212', '+258', '+95', '+264', '+674', '+977', '+31', '+687', '+64', '+505', '+227', '+234', '+683', '+672', '+47', '+968', '+92',
    '+680', '+970', '+507', '+675', '+595', '+51', '+63', '+48', '+351', '+974', '+242', '+262', '+40', '+7', '+250', '+290', '+590', '+508', '+685', '+378', '+239', '+966', '+221', '+381',
    '+248', '+232', '+65', '+421', '+386', '+677', '+252', '+27', '+211', '+34', '+94', '+249', '+597', '+47', '+268', '+46', '+41', '+963', '+886', '+992', '+255', '+66', '+670', '+228', '+690', '+676', '+216', '+90', '+993',
    '+688', '+256', '+380', '+971', '+44', '+1', '+598', '+998', '+678', '+379', '+58', '+84', '+681', '+212', '+967', '+260', '+263'
] as const;

export const countryPhoneCodes = [...new Set(countryPhoneCodesList)];

//Default
export const TurkishBaseModelId = '04fa4a05-63c0-46b5-aa7a-ed4b28201c54';
export const EnglishBaseModelId = '092ce838-fba6-4bc6-ab3b-99f97d662875';