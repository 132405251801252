import React, { memo, ReactElement } from "react";
import { classDecider } from "../utils/helpers";
import styles from "./styles/typography.module.scss";
import { TypographyComponentProps } from "./types/TypographyComponentProps";

const TypographyComponent = memo(
  React.forwardRef<HTMLElement, TypographyComponentProps>(
    (
      {
        label,
        className,
        size = "base",
        weight = "Regular",
        color = "Primary",
        isBlock = false
      }
    ) => {
      let classes =
        styles.text +
        " " +
        classDecider({ size, weight, color }, styles, "text");
      return (
        <>
          {typeof label !== "string" && !Array.isArray(label) ? (
            React.Children.map(label, (child) => {
              return React.cloneElement(
                child as React.ReactElement<HTMLElement>,
                {
                  className: `${classes} ${
                    (child as ReactElement<HTMLElement>)?.props?.className || ""
                  } ${className || ""}`,
                }
              );
            })
          ) : (
              isBlock ? <div className={`${classes} ${className || ""}`}>{label}</div> :
                <span className={`${classes} ${className || ""}`}>{label}</span>
          )}
        </>
      );
    }
  )
);

export const Typography = TypographyComponent;
