import { createSlice } from "@reduxjs/toolkit"
import { afterLogin } from "../../api/services/base/httpBase"

const initialState = {
    authToken: null,
    user: {
        email: null,
        emailConfirmed: null,
        firstName: null,
        id: null,
        lastName: null,
        phoneNumber: null
    },
    lastLogin: null
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthToken: (state, action) => {
            state.authToken = action.payload
            afterLogin(action.payload)
        },
        setUserData: (state, action) => {
            state.user = action.payload
        },
        clearAuthToken: (state, action) => {
            state.authToken = null
        },
        setLastLogin: (state, action) => {
            state.lastLogin = action.payload
        }

    }
})

export const { setAuthToken, clearAuthToken, setUserData, setLastLogin } = authSlice.actions

export default authSlice.reducer