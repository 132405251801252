import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    tags: [{}]
};

export const tagSlice = createSlice({
    name: 'tag',
    initialState,
    reducers: {
        setTags: (state, action) => {
            state.tags = action.payload
       }
    }
})

export const { setTags } = tagSlice.actions

export default tagSlice.reducer