import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from "axios";
import { toast } from "../../../components/toast/ToastHook";
import { API_HOST, MEDIA_SERVER_HOST, PRODUCT_NAME } from "../../../config";
import { store } from "../../../stores";
import { clearAuthToken } from "../../../stores/auth/authSlice";
import { Url_Sign_In } from "../../../utils/routeHelper";
import { ErrorResponse } from "../../contracts/base/response/ErrorResponse";
import { RefreshTokenResponse } from "../../contracts/base/response/RefreshTokenResponse";

const getLanguage = () => {
  return store.getState()?.settings.language;
};

const getScreenName = () => window.location.pathname.split("/").pop();

export const getDefaultHeaders: (token?: string) => AxiosRequestHeaders = (token = "") => {
  return {
    Pragma: "no-cache",
    Accept: "application/json",
    "Accept-Language": getLanguage(),
    product: PRODUCT_NAME,
    screen: getScreenName() ?? "",
    Authorization: "Bearer " + store.getState()?.auth.authToken,
  };
};

export const cloudAIInstance: AxiosInstance = axios.create({
  baseURL: API_HOST,
  //headers: getDefaultHeaders()
});

export const arkApiInstance: AxiosInstance = axios.create({
  baseURL: MEDIA_SERVER_HOST,
  //headers: getDefaultHeaders()
});

const apiInstances = [cloudAIInstance, arkApiInstance];

export const afterLogin = (token: string) => {
  apiInstances.forEach((instance: AxiosInstance) => {
    instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  });
};

let refreshTokenCalled = false;

const setInterceptors = () => {
  apiInstances.forEach((instance: AxiosInstance) => {
    instance.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        let defaultHeaders = getDefaultHeaders();
        config.headers = {
          ...defaultHeaders,
          ...config.headers,
        };
        return config;
      },
      (error) => {
        throw error;
      }
    );

    instance.interceptors.response.use(
      (response: AxiosResponse) => {
        if (response?.data?.errors && response.data.errors[0] && response.data.errors[0].items && response.data.errors[0].items[0]) {
          toast.error(response.data.errors[0].items[0].message);
          throw response.data.errors[0].items[0].message;
        }

        return response;
      },
      (error: AxiosError<ErrorResponse>) => {
        if (error.response?.status === 401) {
          if (!refreshTokenCalled) authErrorHandler();
          else {
            store?.dispatch(clearAuthToken(null));
            window.open(Url_Sign_In, "_self");
          }
        }

        if (error.response?.data && error.response.data?.isSuccessed === false) {
          toast.error(error.response?.data?.errors?.[0]?.items?.[0]?.message ?? "Error!");
        }

        if (error.response?.data && error.response?.data?.isSuccessed === undefined) {
          toast.error(error.response?.data.title ?? error?.message);
        }

        throw error;
      }
    );
  });
};

export const authErrorHandler = () => {
  refreshTokenCalled = true;
  if (store.getState()?.auth.user && store.getState()?.auth?.authToken) {
    refreshToken((store.getState()?.auth.user as any).id, store.getState().auth.authToken as unknown as string);
  }
};

export const refreshToken = async (userId: string, token: string) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: getDefaultHeaders(),
    responseType: "json",
  };
  return await axios
    .post(
      `${API_HOST}identity/user/validateToken`,
      {
        model: {
          userId,
          token,
          tokenType: 1,
        },
      },
      axiosConfig
    )
    .then((response: AxiosResponse<RefreshTokenResponse>) => {
      // if (response?.data?.token) {
      //     store.dispatch(setAuthToken(response?.data?.token))
      //     return new String(response.data.token ?? '')
      // }
      // return { data: [], errors: true }
    })
    .catch((e: AxiosError) => {
      return { data: [], errors: true };
    });
};

setInterceptors();
