import { ComponentType, FC } from "react";
import { Loading } from "../loading/Loading";
import { classDecider } from "../utils/helpers";
import styles from "./styles/button.module.scss";
import {
    ButtonBaseChildProps,
    ButtonBaseComponentProps
} from "./types/ButtonBaseComponentProps";

export const ButtonBaseComponent = <P extends ButtonBaseComponentProps>(
  ChildComponent: ComponentType<ButtonBaseChildProps>
): FC<P> => {
  return ({
    isLoading,
    children,
    color = "primary",
    className,
    size,
    ...props
  }) => {
    let classes = styles.button;
    let colorClass = classDecider(
      { color },
      styles,
      props.variant?.toLowerCase() || "icon"
    );
    classes = classes + " " + classDecider({ size }, styles, "button");

    if (isLoading) {
      return (
        <Loading>
          <ChildComponent
            {...props}
            size={size}
            className={`${classes} ${colorClass} ${className}`}
          >
            {children}
          </ChildComponent>
        </Loading>
      );
    } else {
      return (
        <ChildComponent
          {...props}
          size={size}
          className={`${classes} ${colorClass} ${className}`}
        >
          {children}
        </ChildComponent>
      );
    }
  };
};
