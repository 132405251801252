import { ReactNode, useEffect, useReducer } from "react";
import ToastComponent from "./Toast";
import { ToastContext, ToastProps, ToastType } from "./types/ToastProps";
import { ToastReducedProps } from "./types/ToastReducedProps";
import { initialState } from "./types/ToastStateProps";

export const toastReducer = (state: any, action: ToastReducedProps) => {
  switch (action.type) {
    case "ADD_TOAST":
      return {
        ...state,
        toasts: [...state.toasts, action.payload],
      };
    case "DELETE_TOAST":
      const updatedToasts = state.toasts.filter(
        (toast: ToastProps) => toast.id != action.payload
      );
      return {
        ...state,
        toasts: updatedToasts,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const ToastProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(toastReducer, initialState);

  const addToast = (type: ToastType, message: string) => {
    const id = Math.floor(Math.random() * 10000000).toString();
    dispatch({ type: "ADD_TOAST", payload: { id, message, type } });
  };

  const deleteToast = (id: string) => {
    dispatch({ type: "DELETE_TOAST", payload: id });
  };

  const success = (message: string) => {
    addToast("success", message);
  };

  const warning = (message: string) => {
    addToast("warning", message);
  };

  const info = (message: string) => {
    addToast("info", message);
  };

  const error = (message: string) => {
    addToast("error", message);
  };

  useEffect(() => {
    window.addEventListener("toastMessage", (event: Event) => {
      addToast(
        (event as CustomEvent).detail.type,
        (event as CustomEvent).detail.message
      );
    });
  }, []);

  return (
    <ToastContext.Provider
      value={{ success, warning, info, error, deleteToast }}
    >
      <ToastComponent toasts={state.toasts}></ToastComponent>
      {children}
    </ToastContext.Provider>
  );
};

export { ToastContext, ToastProvider };
