import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    files: []
};

export const fileUploadSlice = createSlice({
    name: 'fileUpload',
    initialState,
    reducers: {
       setFiles: (state, action) => {
            state.files = action.payload
       }
    }
})

export const { setFiles } = fileUploadSlice.actions

export default fileUploadSlice.reducer