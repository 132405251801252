import { useContext } from "react";
import { ToastContext, ToastType } from "./types/ToastProps";

export const useToast = () => useContext(ToastContext);

const initToastEvent = (message: string, type: ToastType) => {
  const customEvent = new CustomEvent("toastMessage", {
    detail: {
      type: type,
      message,
    },
  });

  window.dispatchEvent(customEvent);
};
export const toast = {
  error: (message: string) => {
    initToastEvent(message, "error");
  },
  success: (message: string) => {
    initToastEvent(message, "success");
  },
};
