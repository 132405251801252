export const classDecider =(
    props: Record<string, any>, 
    styles: {
        readonly [key: string]: string;
    },
    target: string): string => {
    let classes = ''

    Object.keys(props).forEach((prop: string) => {
        if (typeof props[prop] !== 'undefined') { 
            if (typeof props[prop] === 'boolean') {
                if (props[prop]) {
                    classes = classes + ' ' + styles[`${target}--${prop}`]
                }
            } else {
                classes = classes + ' ' + styles[`${target}__${prop}--${props[prop]?.toString()?.toLowerCase()}`]
            }
        }
    })

    return classes;
}