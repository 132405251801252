import React from "react";
import { Button } from "../button/Button";
import { Times } from "../icons";
import { Typography } from "../typography/Typography";
import styles from "./styles/toast.module.scss";
import { useToast } from "./ToastHook";
import { ToastProps } from "./types/ToastProps";
import { ToastStateProps } from "./types/ToastStateProps";
import { toastTypes } from "./types/ToastTypes";

const ToastComponent = ({ toasts }: ToastStateProps) => {
  const toastHook = useToast();

  const removeToastMessage = (event: React.AnimationEvent<HTMLDivElement>) => {
    const targetToastId = (event.target as HTMLDivElement).getAttribute("id");
    if (event.animationName.includes("out") && targetToastId !== null) {
      toastHook?.deleteToast(targetToastId);
    }
  };

  return (
    <div className={`${styles.toast__container}`}>
      {toasts.map((toast: ToastProps) => {
        const { progressBarClass, ...toastProps } = toastTypes[toast.type];
        const statusClass = styles[`toast__${progressBarClass}`];

        return (
          <div
            key={toast.id}
            id={toast.id}
            className={`${styles.toast} ${statusClass} m-2 py-3 px-2`}
            onAnimationEnd={removeToastMessage}
          >
            <div className={`${styles.toast__icon} me-2`}>
              {<toastProps.icon />}
            </div>
            <Typography weight="regular" color="white" size="sm" label={<p className={styles.toast__message}>{toast.message}</p>} />
            <Button
              variant="text"
              onClick={() => {
                toastHook?.deleteToast(toast.id);
              }}
              className={`${styles.toast__dismiss} mx-2`}
            >
              <Times />
            </Button>
          </div>
        );
      })}
    </div>
  );
};

export default ToastComponent;
