import { configureStore, ThunkMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistStore } from "redux-persist";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import authReducer from "./auth/authSlice";
import cartReducer from "./cart/cartSlice";
import fileUploadReducer from "./fileUpload/fileUploadSlice";
import liveInterfaceReducer from "./liveInterface/liveInterfaceSlice";
import settingsReducer from "./settings/settingsSlice";
import tagReducer from "./tag/tagSlice";

const rootPersistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["auth", "settings"],
};

const appReducer = combineReducers({
  auth: authReducer,
  settings: settingsReducer,
  cart: cartReducer,
  fileUpload: fileUploadReducer,
  tag: tagReducer,
  liveInterface: liveInterfaceReducer,
});

const persistedReducer = persistReducer(rootPersistConfig, appReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (gdm) =>
    gdm({
      serializableCheck: false,
    }).concat(thunk as ThunkMiddleware),
});

export const persistor = persistStore(store);
