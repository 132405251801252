const { apiHost, mediaServerHost}: any = (window as { [key: string]: any })["runConfig"] as any ?? "";

let API_HOST = apiHost;
let MEDIA_SERVER_HOST = mediaServerHost;
const PRODUCT_NAME = "ArkApi";
const PROCESS_ENV = process.env.REACT_APP_ENVIRONMENT;
const PRODUCT_LOGO = '/assets/images/Logo.svg'
const PRODUCT_LOGO_LIGHT = '/assets/images/Logo_Light.svg'

export { API_HOST, MEDIA_SERVER_HOST, PROCESS_ENV, PRODUCT_LOGO, PRODUCT_LOGO_LIGHT, PRODUCT_NAME };
