import { createSlice } from "@reduxjs/toolkit";
import { SupportedLanguage } from "../../enums/supported-language";
import * as Keys from "../../translations/keys";

const initialState = {
    language: SupportedLanguage.Tur,
    secureHTML: null,
    token: null,
    breadcrumbs: [Keys.Home],
    activeCodeEditorTabId: "api-reference" 
};

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setLanguage: (state, action) => {
            state.language = action.payload
        },
        setSecureHTML: (state, action) => {
            state.secureHTML = action.payload.html
            state.token = action.payload.token
        },
        setBreadCrumbs: (state, action) => {
            state.breadcrumbs = action.payload
        },
        setActiveCodeEditorTabId: (state, action) => {
            state.activeCodeEditorTabId = action.payload
        }
    }
})

export const { setLanguage, setSecureHTML, setBreadCrumbs, setActiveCodeEditorTabId } = settingsSlice.actions

export default settingsSlice.reducer