import React from "react";
import { classDecider } from "../utils/helpers";
import { ButtonBaseComponent } from "./ButtonBase";
import { IconButtonComponentProps } from "./types/IconButtonComponentProps";
import styles from "./styles/button.module.scss";

const IconButtonComponent = React.forwardRef<
  HTMLButtonElement,
  IconButtonComponentProps
>(
  (
    {
      id,
      className,
      size,
      passive = false,
      href,
      onClick,
      disabled = false,
      icon,
      ...props
    },
    ref
  ) => {
    let classes = classDecider(
      { passive, size, disabled },
      styles,
      "button__icon"
    );

    return (
      <button
        id={id}
        disabled={disabled}
        onClick={onClick}
        type="button"
        className={` ${classes} ${className ?? ""} ${styles.button__icon}`}
        {...props}
      >
        {icon}
      </button>
    );
  }
);

export const IconButton =
  ButtonBaseComponent<IconButtonComponentProps>(IconButtonComponent);
