import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    plan: null,
    pageCount: 0,
    billing: null,
    cardNumber: '',
    cvv: '',
    expiryMonth: '',
    expiryYear: '',
    ccOwner: ''
}

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        setPlan: (state, action) => {
            state.plan = action.payload.plan
            state.pageCount = action.payload.pageCount
        },
        updateBillingInfo: (state, action) => {
            state.billing = action.payload
        },
        setPaymentInfo: (state, action) => {
            state.cardNumber = action.payload.cardNumber
            state.cvv = action.payload.cvv
            state.expiryMonth = action.payload.expiryDate.split('/')[0]
            state.expiryYear = action.payload.expiryDate.split('/')[1]
            state.ccOwner = action.payload.ccOwner
        }
    }
})

export const { setPlan, updateBillingInfo, setPaymentInfo } = cartSlice.actions

export default cartSlice.reducer